<template>
  <div class="lnb">
    <ul class="tight">
      <li :class="{ 'color-point bold': $route.path === item.url }" v-for="(item, idx) in items" :key="idx">
        <router-link :to="item.url">{{ item.title }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pagePolicyLnb";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    const items = [
      {
        title: "회원 기본약관",
        url: "/policy/terms",
      },
      {
        title: "후원형 이용약관",
        url: "/policy/rewardTerms",
      },
      {
        title: "증권형 이용약관",
        url: "/policy/investTerms",
      },
      {
        title: "후원형 개인정보처리방침",
        url: "/policy/rewardPrivacy",
      },
      {
        title: "증권형 개인정보처리방침",
        url: "/policy/investPrivacy",
      },
    ];

    return {component, items};
  },
});
</script>

<style lang="scss" scoped>
.lnb {
  border: $px1 solid #eee;
  padding: $px10 $px25;

  li {
    display: block;
    font-size: $px14;
    padding: $px13 0;

    a {
      display: block;
    }

    &:last-child {
      margin-bottom: $px10;
      border-bottom: 0;
    }
  }
}
</style>